"use client";

import { Modal, ModalContent } from "@nextui-org/modal";
import { useDisclosure } from "@nextui-org/use-disclosure";
import {
  createContext,
  ReactNode,
  startTransition,
  useCallback,
  useContext,
  useRef,
  useState,
  useTransition,
} from "react";
import OTPInput from "react-otp-input";
import Image from "next/image";
import { Button } from "../custom";
import { VerifySecurityPin } from "@/lib/actions";
import clsx from "clsx";

interface SecurityPinContextType {
  openSecurityPinModal: () => Promise<void>;
  closeSecurityPinModal: (failed?: boolean, reason?: string) => void;
}

const SecurityPinContext = createContext<SecurityPinContextType>({} as SecurityPinContextType);

export const SecurityPinProvider = ({ children }: { readonly children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [otp, setOtp] = useState("");
  const [invalid, setInvalid] = useState(false);
  const resolveRef = useRef<(value: void | PromiseLike<void>) => void>();
  const rejectRef = useRef<(reason?: any) => void>();

  const openModal: () => Promise<void> = () => {
    onOpen();
    return new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  const closeModal = (failed?: boolean, reason?: string) => {
    if (rejectRef.current && failed) {
      rejectRef.current(reason);
      rejectRef.current = undefined;
    } else if (resolveRef.current) {
      resolveRef.current();
      resolveRef.current = undefined;
    }
    onClose();
  };

  // useEffect(() => {
  //   document.addEventListener("keydown", (e) => {
  //     console.log(e.key);

  //     if (e.key === "o") {
  //       console.log("Open Modal");
  //       openModal().then(() => console.log("Modal Closed"));
  //     }
  //   });
  // }, []);

  const [pending] = useTransition();

  const onSubmit = useCallback(
    () =>
      startTransition(async () => {
        console.log("OTP", otp);
        const res = await VerifySecurityPin(otp);
        if ("errors" in res) {
          console.log(res);
          setOtp("");
          setInvalid(true);
          return;
        }

        closeModal();
      }),
    [otp]
  );

  return (
    <SecurityPinContext.Provider value={{ openSecurityPinModal: openModal, closeSecurityPinModal: closeModal }}>
      {children}
      <Modal
        isOpen={isOpen}
        onClose={() => closeModal(true, "Modal closed")}
        size="sm"
        scrollBehavior="inside"
        classNames={{
          body: "bg-[#021517]",
          header: "bg-[#0A2A27] shadow-lg rounded-t-lg",
          closeButton: clsx("hover:bg-white/5 active:bg-white/10"),
        }}
        className="z-[101]"
        placement="center"
        radius="lg"
      >
        <ModalContent className="p-12">
          <div className="flex w-full flex-col items-center gap-4">
            {/* <div className="flex w-full items-center justify-start">
              <Link href="/login">
                <BsFillArrowLeftCircleFill size={40} color="#D8D8D8" />
              </Link>
            </div> */}
            <div className="flex items-center justify-center">
              <Image src={"/assets/img/auth/success-new-password.png"} alt="Security Pin" width={80} height={80} />
            </div>
            <div>
              <div className="text-center text-[24px] font-bold">Security Verification</div>
              {/* <div className="text-center text-xs text-[#8f8f8f]">
                We've sent a six digits code verification to {searchParams.get("email")}.
              </div> */}
            </div>
            <div
              className="mt-4 flex flex-col gap-2"
              onFocus={() => {
                setInvalid(false);
              }}
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                // renderSeparator={<div className="w-2" />}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="mx-1 aspect-square !h-auto !w-12 rounded-lg border bg-white text-center text-lg focus:!outline-yellow-400"
                  />
                )}
              />
              {invalid && (
                <span className="text-center text-tiny text-danger">Security pin incorrect, please try again.</span>
              )}
            </div>
            {/* {!expired ? (
              <span className="my-4 text-sm">
                OTP expires in{" "}
                {
                  <Countdown
                    ref={countdownRef}
                    onComplete={() => setExpired(true)}
                    renderer={({ minutes, seconds }) => {
                      return (
                        <span>
                          {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                        </span>
                      );
                    }}
                    date={date}
                  />
                }
              </span>
            ) : (
              <>
                <div className="my-4 text-sm">
                  Didn't receive the email?{" "}
                  <span
                    onClick={() => onResend()}
                    className="cursor-pointer text-primary underline hover:text-[#ffe68a]"
                  >
                    Resend OTP
                  </span>
                </div>
              </>
            )} */}
            <div className="my-4 w-full">
              <Button
                onPress={onSubmit}
                className="w-full font-bold text-black disabled:bg-slate-400"
                isDisabled={otp.length !== 6 || pending}
              >
                Submit
              </Button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </SecurityPinContext.Provider>
  );
};

export const useSecurityPin = () => {
  const context = useContext(SecurityPinContext);
  if (context === undefined) {
    throw new Error("useSecurityPin must be used within a SecurityPinProvider");
  }
  return context;
};
