"use client";

import { Input as _Input, InputProps } from "@nextui-org/input";
import { Button as _Button, ButtonProps } from "@nextui-org/button";
import { Select as _Select, SelectProps, SelectItem } from "@nextui-org/select";
import { Snippet as _snippet } from "@nextui-org/snippet";
import Image from "next/image";
import clsx from "clsx";
import { countries, getEmojiFlag, TCountryCode } from "countries-list";
import { useEffect, useRef, useState } from "react";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa6";
import { SnippetProps } from "@nextui-org/snippet";
import { DatePicker as _DatePicker, Autocomplete, AutocompleteItem, DatePickerProps, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer } from "@nextui-org/react";
import { useDisclosure, UseDisclosureReturn } from "@nextui-org/use-disclosure";
import { useUA } from "@/app/context/useUA";

export const Snippet = (props: SnippetProps) =>
{
  return (
    <_snippet
      symbol=""
      size="md"
      tooltipProps={{
        color: "foreground",
        content: "Copy referral code",
        disableAnimation: true,
        placement: "right",
        closeDelay: 0,
      }}
      radius="sm"
      className="pl-4"
      classNames={{ pre: "whitespace-pre-wrap" }}
      fullWidth
      {...props}
    />
  );
};


export const Input = (props: InputProps) =>
{
  return (
    <_Input
      type="text"
      labelPlacement="outside"
      autoComplete="one-time-code"
      size="md"
      radius="sm"
      variant="bordered"
      classNames={{
        base: clsx(""),
        input: clsx("placeholder:text-[#BBBBBB] text-[16px] md:text-small"),
        inputWrapper: clsx("bg-white border-1 border-[#D8D8D8]"),
      }}
      {...props}
    />
  );
};

export const DatePicker = (props: DatePickerProps) => (
  <_DatePicker radius="sm" size="md" variant="bordered" className="text-[16px] md:text-small" labelPlacement="outside" inputMode="search" {...props} />
);

export const Select = (props: SelectProps) =>
{
  return <_Select radius="sm" size="md" variant="bordered" labelPlacement="outside" {...props} />;
};

export const SecurityPinInput = (props: InputProps) =>
{
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState<string>("");
  return (
    <_Input
      labelPlacement="outside"
      size="md"
      radius="sm"
      variant="bordered"
      onValueChange={(v) =>
      {
        if (v.length > 6) return;
        setValue(v.replace(/\D/g, ""));
      }}
      value={value}
      type={showPassword ? "text" : "password"}
      classNames={{
        base: clsx(""),
        input: clsx("placeholder:text-[#BBBBBB] text-[16px] md:text-small"),
        inputWrapper: clsx("bg-white border-1 border-[#D8D8D8]"),
      }}
      className="w-full"
      startContent={<FaLock color="#FFCA05" size={16} />}
      endContent={
        <a className="cursor-pointer" onClick={() => setShowPassword((p) => !p)}>
          {showPassword ? <FaEye color="#8D8D8D" size={16} /> : <FaEyeSlash color="#8D8D8D" size={16} />}
        </a>
      }
      {...props}
    />
  );
};

export const PasswordInput = (props: InputProps) =>
{
  const [showPassword, setShowPassword] = useState(false);
  return (
    <_Input
      labelPlacement="outside"
      size="md"
      radius="sm"
      variant="bordered"
      type={showPassword ? "text" : "password"}
      autoComplete="null-password"
      classNames={{
        base: clsx(""),
        input: clsx("placeholder:text-[#BBBBBB] text-[16px] md:text-small"),
        inputWrapper: clsx("bg-white border-1 border-[#D8D8D8]"),
      }}
      className="w-full"
      startContent={<FaLock color="#FFCA05" size={16} />}
      endContent={
        <a className="cursor-pointer" onClick={() => setShowPassword((p) => !p)}>
          {showPassword ? <FaEye color="#8D8D8D" size={16} /> : <FaEyeSlash color="#8D8D8D" size={16} />}
        </a>
      }
      {...props}
    />
  );
};

export const Button = (props: ButtonProps) =>
{
  return <_Button size="md" color="primary" radius="sm" className="w-full text-base font-bold text-black" {...props} />;
};

const CountriesMobileModal = ({ disclosure, value, setValue }: { disclosure: UseDisclosureReturn, value: any, setValue: any }) =>
{
  return <Modal placement="top" isOpen={disclosure.isOpen} onOpenChange={disclosure.onOpenChange} defaultOpen>
    <ModalContent>
      <ModalHeader />
      <ModalBody>
        <_Select
          size="md"
          radius="sm"
          variant="bordered"
          aria-label="Countries Search"
          classNames={{
            trigger: clsx("border-1 border-[#D8D8D8] bg-white"),
          }}
          selectedKeys={[value]}
          startContent={<div className={clsx("aspect-square !h-[1.5rem] !w-auto rounded-full", `flag:${value}`)} />}
          items={Object.entries(countries)
            .map(([key, value]) => ({
              key,
              label: `+${value.phone[0]}`,
              flag: <div className={clsx("aspect-square !h-[1.5rem] !w-auto rounded-full", `flag:${key}`)} />,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={(e) => (setValue(e.target.value), disclosure.onClose())}
        >
          {(item) => (
            <SelectItem key={item.key} value={item.label} startContent={item.flag}>
              {item.label}
            </SelectItem>
          )}
        </_Select>
      </ModalBody>
      <ModalFooter />
    </ModalContent>
  </Modal>
}

export const CountrySelect = (props: Omit<SelectProps, "children">) =>
{
  const [flag, setFlag] = useState<any>("MY");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const disclosure = useDisclosure();
  const ua = useUA();
  return (
    <>
      <CountriesMobileModal disclosure={disclosure} value={flag} setValue={setFlag} />
      <_Select
        size="md"
        radius="sm"
        variant="bordered"
        isOpen={isOpen}
        aria-controls="country-select"
        onOpenChange={(open) => ua?.getDevice().type === "mobile" ? disclosure.onOpen() : setIsOpen(open)}
        classNames={{
          trigger: clsx("border-1 border-[#D8D8D8] bg-white"),
        }}
        startContent={<div className={clsx("aspect-square !h-[1.5rem] !w-auto rounded-full", `flag:${flag}`)} />}
        {...props}
        selectedKeys={[flag]}
        items={Object.entries(countries)
          .map(([key, value]) => ({
            key,
            label: `+${value.phone[0]}`,
            flag: <div className={clsx("aspect-square !h-[1.5rem] !w-auto rounded-full", `flag:${key}`)} />,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        onChange={(e) => setFlag(e.target.value)}
      >
        {(item) => (
          <SelectItem key={item.key} value={item.label} startContent={item.flag}>
            {item.label}
          </SelectItem>
        )}
      </_Select></>
  );
};
