"use client";

import { createContext, useContext, useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";


const MenuContext = createContext<UAParser.UAParserInstance | undefined>(undefined);

export function UAProvider({ children }: { children: React.ReactNode;}) {

  const [ua, setUA] = useState<UAParser.UAParserInstance>();
  useEffect(() =>
  {
    if(navigator)
    {
      setUA(new UAParser(navigator.userAgent));
    }
    
  }, []);

  return <MenuContext.Provider value={ua}>{children}</MenuContext.Provider>;
}

export function useUA() {
  const context = useContext(MenuContext);
  // if (!context) {
  //   throw new Error("useUA must be used within a UAProvider");
  // }
  return context;
}
